
import { Employee, EmployeeFilter } from '@/models/Employee';
import { generalStore } from '@/store';
import { computed, defineComponent, ref } from 'vue';
import router from '@/router';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { onActivated } from 'vue';
import { useRoute } from 'vue-router';
import Q from 'q';
import { useI18n } from 'vue-i18n';
import { OrdersFilter } from '@/models/Order';
import { ApiResult } from '@/services/BaseApi';
import { AwaitTaskResponse } from '@/models/Interfaces';

export default defineComponent({
    name: 'EmployeesTable',
    beforeRouteEnter(to, from, next) {
        if ((from.name != 'modal-employees-filter' && from.name != 'EmployeeView') || to.params.newSearch) {
            to.params.newSearch = '1';
        } else {
            to.params.newSearch = '';
        }
        next();
    },
    async setup() {
        const { t } = useI18n();
        const route = useRoute();
        const employees = ref<Employee[]>([]);
        const filter = computed<EmployeeFilter>(() => generalStore.state.employeesFilter);
        const employeesTotal = ref(0);
        const showOnlyActiveEmployees = ref(true);

        const hasFilter = computed<boolean>(() => {
            if (filter.value.tagIds.length) {
                return true;
            }
            if (filter.value.schedules.length) {
                return true;
            }
            return false;
        });
        const ShowFilter = () => {
            router.push({ name: 'modal-employees-filter' });
        };

        async function getEmployees() {
            swal.showLoading();
            filter.value.status = showOnlyActiveEmployees.value;
            const apiResult = await api.getEmployees(filter.value);
            await Q.delay(400);
            if (apiResult.data?.error) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error
                });
                return;
            }
            if (apiResult.data) {
                employees.value = apiResult.data?.employees;
                employeesTotal.value = apiResult.data?.total;
            }
            swal.close();
        }

        async function onActivatedAction() {
            if (route.params.newSearch) {
                employees.value = [];
                await getEmployees();
            }
        }
        onActivated(onActivatedAction);

        if (!employees.value.length) {
            await getEmployees();
        }
        async function routeToEmployeePage(employeeId: string) {
            swal.showLoading();
            router.push({ name: 'EmployeeView', params: { id: employeeId } });
        }

        async function goCreateEditEmployee(employeeId = 0) {
            swal.showLoading();
            filter.value.keyword = '';
            if (employeeId == 0) {
                generalStore.commit('setEmployee', new Employee());
            }
            router.push({ name: 'EditEmployee', params: { id: employeeId } });
        }

        function goToSlotsWithNoEmployee() {
            swal.showLoading();
            router.push({ name: 'SlotsWithNoEmployee' });
        }

        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        async function showAll() {
            generalStore.commit('updateEmployeesFilter', new EmployeeFilter());
            await getEmployees();
        }

        async function awaitTask(action: () => Promise<ApiResult<AwaitTaskResponse>>, onSuccess: (url: string) => void, waitTime = 3000) {
            swal.fire(t('swalAction.processing'));
            swal.showLoading();
            await Q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        const exportEmployees = async () => {
            swal.showLoading();
            const filterData: EmployeeFilter = JSON.parse(JSON.stringify(filter.value));

            const apiResult = await api.exportEmployees(filterData);
            await Q.delay(400);
            if (apiResult.data?.error || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error || 'No data'
                });
                return;
            }
            const taskId = apiResult.data.taskId;
            awaitTask(
                () => api.waitExportTask(taskId),
                url => {
                    swal.fire({
                        icon: 'success',
                        title: t('alert.exportReady.orders'),
                        html: `
                    <div class="text-center my-4">
                        <a href="${url}" target="_blank">
                            <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                        </a>
                    </div>`,
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            );
        };

        function showWarning(index: number) {
            document.getElementById('product-update-' + index)?.classList.remove('dp-none');
            document.getElementById('product-update-' + index)?.classList.add('dp-block');
        }
        function hideWarning(index: number) {
            document.getElementById('product-update-' + index)?.classList.remove('dp-block');
            document.getElementById('product-update-' + index)?.classList.add('dp-none');
        }

        return {
            hideWarning,
            showWarning,
            getEmployees,
            filter,
            employees,
            employeesTotal,
            routeToEmployeePage,
            goCreateEditEmployee,
            getCurrencySymbol,
            goToSlotsWithNoEmployee,
            ShowFilter,
            hasFilter,
            showAll,
            exportEmployees,
            showOnlyActiveEmployees
        };
    }
});
